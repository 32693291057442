import type { AcceptedLanguages } from "./constants";

import type { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import axios from "axios";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: String(process.env["NEXT_PUBLIC_API_URL"] ?? ""),
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  /* for future use if needed to add headers to all requests
  and also to get token from cookie and add it to request  */
  config.headers.set("source", "MA");
  return config;
});

export const setAxiosLanguageHeader = (language: AcceptedLanguages): void => {
  axiosInstance.defaults.headers.common["language"] = language;
};

export default axiosInstance;
