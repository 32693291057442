import dynamic from "next/dynamic";
import type { ComponentType, PropsWithChildren } from "react";

export enum LayoutType {
  Default = "default",
  Login = "login",
  Guide = "guide",
  Wizard = "wizard",
}

export const layouts: Record<LayoutType, ComponentType<PropsWithChildren>> = {
  [LayoutType.Default]: dynamic(async () => import("./templates/defaultLayout")),
  [LayoutType.Login]: dynamic(async () => import("./templates/loginLayout")),
  [LayoutType.Guide]: dynamic(async () => import("./templates/guideLayout")),
  [LayoutType.Wizard]: dynamic(async () => import("./templates/wizardLayout")),
} as const;
