import type { Callback, Consumer, Nullable } from "~utils/types";
import type { OpeningHours } from "~utils/types/global-types";

export enum CreateServicePageSteps {
  CREATE_SERVICE = "create_service",
  CONFIRMATION = "confirmation",
}

export type OpeningHoursData = OpeningHours<Date>;

export type OpeningHoursDataStored = OpeningHours<string>;

type CommonState = {
  currentStep: CreateServicePageSteps;
  requiredOperationCategory: Nullable<string>;
};
export type CreateServicePageStateStored = CommonState & {
  openingHoursData: OpeningHoursDataStored;
};

export type CreateServicePageState = CommonState & {
  openingHoursData: OpeningHoursData;
};

export type UseCreateServicePageState = {
  setCurrentStep: Consumer<CreateServicePageSteps>;
  setRequiredOperationCategory: Consumer<string>;
  setOpeningHoursData: Consumer<OpeningHoursData>;
  resetState: Callback;
};
