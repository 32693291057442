/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";
import type { Guest, HotelRoom } from "~utils/types/global-types";

import type { NewTaskPageModalsEnum, NewTaskPageModalsState, TaskTypeEnum } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: NewTaskPageModalsState = {
  taskCreated: false,
  currentModal: null,
  currentTaskType: null,
  guestSearchKeyword: null,
  currentGuest: null,
  currentRoom: null,
};

const newTaskPageModalsSlice = createSlice({
  name: "new-task-page",
  initialState,
  reducers: {
    setTaskCreated: (state, action: PayloadAction<boolean>) => {
      const draft = state;
      draft.taskCreated = action.payload;
    },
    setCurrentModal: (state, action: PayloadAction<Nullable<NewTaskPageModalsEnum>>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setCurrentTaskType: (state, action: PayloadAction<TaskTypeEnum>) => {
      const draft = state;
      draft.currentTaskType = action.payload;
    },
    setGuestSearchKeyword: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.guestSearchKeyword = action.payload;
    },
    setCurrentGuest: (state, action: PayloadAction<Nullable<Guest>>) => {
      const draft = state;
      draft.currentGuest = action.payload;
    },
    setCurrentRoom: (state, action: PayloadAction<Nullable<HotelRoom>>) => {
      const draft = state;
      draft.currentRoom = action.payload;
    },
    closeModals: (state) => {
      const draft = state;
      draft.currentModal = null;
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});

export const {
  setCurrentModal,
  setTaskCreated,
  setCurrentGuest,
  setCurrentRoom,
  setCurrentTaskType,
  setGuestSearchKeyword,
  closeModals,
  resetState,
} = newTaskPageModalsSlice.actions;

export const { reducer: newTaskPageModalsReducer } = newTaskPageModalsSlice;
