import { useEffect, useRef } from "react";

import { useGetCurrentUser } from "~store/current-user/hooks";
import { useSelectCurrentUser } from "~store/current-user/selector";
import { isDefined } from "~utils/boolean-utils";
import type { Nullable } from "~utils/types";

import { EventsTracker } from "./mixpanel";
import type { CreateTrackEventHandlerFn, TrackEventFn, UseTrackerType } from "./types";
import { UserEventsEnum } from "./types";

import { useRouter } from "next/router";

export const useEventTracker: UseTrackerType = () => {
  const instance = EventsTracker.getInstance();
  const { pathname } = useRouter();
  const user = useSelectCurrentUser();

  const trackEvent: TrackEventFn = ({ event, eventProps }) => {
    const basicProps = isDefined(user)
      ? {
          id: user.id,
          username: user.username,
          hotel: user.hotel.name,
          page: pathname,
        }
      : {};
    instance.trackEvent(event, { ...basicProps, ...eventProps });
  };

  const createTrackEventHandler: CreateTrackEventHandlerFn = ({ callback, ...eventData }) => {
    return () => {
      callback();
      trackEvent({ ...eventData });
    };
  };

  return {
    createTrackEventHandler,
    trackEvent,
  };
};

export const useInitTracking = (pageTitle: string): void => {
  const instance = EventsTracker.getInstance();
  const previousPageTitle = useRef<Nullable<string>>(null);
  const { trackEvent } = useEventTracker();
  const { isLoading: isFetchingUser } = useGetCurrentUser();

  useEffect(() => {
    instance.init();
  }, []);

  useEffect(() => {
    if (!isFetchingUser && isDefined(pageTitle) && pageTitle !== previousPageTitle.current) {
      trackEvent({
        event: UserEventsEnum.PAGE_VIEW,
        eventProps: {
          pageTitle,
        },
      });
      previousPageTitle.current = pageTitle;
    }
  }, [pageTitle, isFetchingUser]);
};
