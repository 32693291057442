import useUpdateEffect from "~src/common/hooks/useUpdateEffect";
import { useSelectGuestsFilters } from "~store/guest-filters/selectors";
import { useAppDispatch } from "~store/hooks";

import { useGuestsSearchParamsSelector } from "./selectors";
import { resetPageNumber, resetParams, setKeyword, setPageLimit, setPageNumber } from "./slice";
import type { GuestTableSearchParams, IUpdateGuestsPageSearchParams } from "./types";

export const useGuestsPageSearchParams = (): IUpdateGuestsPageSearchParams => {
  const dispatch = useAppDispatch();

  const updatePageNumber = (page: number) => {
    dispatch(setPageNumber(page));
  };

  const updatePageLimit = (limit: number) => {
    dispatch(setPageLimit(limit));
  };
  const updateKeyword = (keyword: string) => {
    dispatch(setKeyword(keyword));
  };
  const resetPageNumberFn = () => {
    dispatch(resetPageNumber());
  };
  const resetParamsFn = () => {
    dispatch(resetParams());
  };
  return {
    updatePageNumber,
    updatePageLimit,
    updateKeyword,
    resetPageNumber: resetPageNumberFn,
    resetParams: resetParamsFn,
  };
};

export const useGetGuestsPageSearchParams = (): Readonly<GuestTableSearchParams> => {
  const data = useGuestsSearchParamsSelector();

  const { resetPageNumber: resetPageNumberToInitial } = useGuestsPageSearchParams();
  const { appliedFilters } = useSelectGuestsFilters();

  useUpdateEffect(() => {
    resetPageNumberToInitial();
  }, [appliedFilters, data.keyword]);

  return data;
};
