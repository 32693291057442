import { useEffect, useRef } from "react";

import type { Callback } from "~utils/types";

const useUpdateEffect = (effect: Callback, dependencies: unknown[]): void => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Skip first render
      return;
    }

    effect(); // Call the effect on subsequent renders
  }, dependencies);
};

export default useUpdateEffect;
