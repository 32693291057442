import type { Consumer } from "~utils/types";

export enum TeamsPageTabsEnum {
  TEAMS = "teams",
  EMPLOYEES = "employees",
}

export type TeamsPageState = {
  currentTab: TeamsPageTabsEnum;
};

export type UseTeamsPageStateReturnType = {
  setCurrentTab: Consumer<TeamsPageTabsEnum>;
};
