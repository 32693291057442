import { AcceptedLanguages } from "./constants";
import type { NulllableOrUndefined } from "./types";

export const isNullOrUndefined = <T>(value: NulllableOrUndefined<T>): value is null | undefined => {
  return value === null || value === undefined;
};

export const isDefined = <T>(value: NulllableOrUndefined<T>): value is T => !isNullOrUndefined(value);

export const isBlank = (str: NulllableOrUndefined<string>): boolean => {
  return isNullOrUndefined(str) || str === "";
};

export const isNotBlank = (str: NulllableOrUndefined<string>): boolean => {
  return !isBlank(str);
};

export const isEmpty = (arr: unknown[]): boolean => {
  return arr.length === 0;
};

export const isNotEmpty = (arr: unknown[]): boolean => {
  return !isEmpty(arr);
};

export const isValidLanguageCode = (language: string): boolean => {
  return Object.values(AcceptedLanguages).includes(language as AcceptedLanguages);
};

export const isNotNumber = (value: unknown): boolean => {
  return isNaN(value as never);
};

export const isNumber = (value: unknown): boolean => {
  return !isNotNumber(value);
};
