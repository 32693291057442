import type { TeamsPageState } from "./types";
import { TeamsPageTabsEnum } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: TeamsPageState = {
  currentTab: TeamsPageTabsEnum.TEAMS,
};

const teamsPageSlice = createSlice({
  name: "teams-page",
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<TeamsPageTabsEnum>) => {
      const draft = state;
      draft.currentTab = action.payload;
    },
  },
});

export const { setCurrentTab } = teamsPageSlice.actions;

export const { reducer: teamsPageReducer } = teamsPageSlice;
