// /* eslint-disable prettier/prettier */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { isNullOrUndefined } from "~utils/boolean-utils";
import { voidFunction } from "~utils/functions";
import type { Nullable } from "~utils/types";

import type { UserEventsEnum } from "./types";

import type { Dict } from "mixpanel-browser";
import mixpanel from "mixpanel-browser";
const env = process.env["NEXT_PUBLIC_ENVIRONEMENT"] ?? "PRODUCTION";
const token = process.env["NEXT_PUBLIC_MIXPANEL_TOKEN"] ?? "";

export class EventsTracker {
  private static instance: Nullable<EventsTracker>;
  private initialized: boolean = false;

  public static getInstance(): EventsTracker {
    if (isNullOrUndefined(this.instance)) {
      this.instance = new EventsTracker();
    }

    return this.instance;
  }

  public init(): void {
    if (!this.initialized && !["TEST", "LOCAL"].includes(env)) {
      mixpanel.init(token, {
        debug: process.env.NODE_ENV !== "production",
        track_pageview: false,
        persistence: "localStorage",
      });
    }
    this.initialized = true;
  }

  public trackEvent(eventName: UserEventsEnum, props?: Dict): void {
    if (!["TEST", "LOCAL"].includes(env)) {
      try {
        mixpanel.track(eventName, props);
      } catch (error: unknown) {
        this.onError(error);
      }
    }
  }

  private onError(_error: unknown): void {
    voidFunction();
  }
}
