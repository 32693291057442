/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-use-before-define */
import type { AcceptedLanguages } from "~utils/constants";
import type { Nullable } from "~utils/types";

export type HotelPermissions =
  | "create_booking"
  | "create_incident"
  | "create_internal_task"
  | "create_order"
  | "create_request"
  | "see_configuration_operations"
  | "see_configuration_services"
  | "see_create_guest_need"
  | "see_create_incident"
  | "see_guests"
  | "see_reporting"
  | "see_tasks"
  | "unlimited_languages";

export type UserRoleCodeEnum = "hotel_manager" | "marketing" | "staff" | "team_manager" | "tech";

export type UserPermissions =
  | "create_booking"
  | "create_guest_need"
  | "create_guest"
  | "create_incident"
  | "create_internal_task"
  | "create_order"
  | "create_request"
  | "create_stay"
  | "delete_guest"
  | "edit_employees"
  | "edit_establishment"
  | "edit_guest"
  | "edit_locations"
  | "edit_operations"
  | "edit_services"
  | "list_all_employees"
  | "list_all_employees"
  | "list_all_guests"
  | "list_all_reporting"
  | "list_all_tasks"
  | "list_own_tasks"
  | "list_team_reporting"
  | "list_team_tasks"
  | "manage_tasks"
  | "see_clients_reporting"
  | "see_employees"
  | "see_faqs"
  | "see_incidents_reporting"
  | "see_locations"
  | "see_notifications"
  | "see_operations_perfomance_reporting"
  | "see_operations"
  | "see_services"
  | "see_teams";

export enum ESortDirections {
  ASC = "ASC",
  DESC = "DESC",
}

export interface HotelPermission {
  id: string;
  code: HotelPermissions;
  name: string;
  description: string;
}

export enum UsernameTypeEnum {
  USERNAME = "username",
  EMAIL = "email",
  PHONE = "phone",
}

export enum EstablishmentTypeEnum {
  HOTEL = "HOTEL",
  GYM = "GYM",
  AIRBNB = "AIRBNB",
  EVENT = "EVENT",
  INDUSTRY = "INDUSTRY",
  F_AND_B = "F_AND_B",
}

export enum NotificationPlatformEnum {
  WHATSAPP = "whatsapp",
  EMAIL = "email",
}
export enum RequestTypeEnum {
  EMPLOYEE = "EMPLOYEE",
  GUEST = "GUEST",
}

export interface Hotel {
  id: string;
  type: EstablishmentTypeEnum;
  name: string;
  excerpt: string;
  description: string;
  logo: string;
  settings: string;
  phoneNumber: string;
  acceptedLanguages: string;
  country: string;
  street: string;
  city: string;
  butlerName: string;
  zipcode: string;
  defaultTimezone: string;
  defaultLanguage: AcceptedLanguages;
  permissions: HotelPermission[];
  locations: Location[];
  incidentCategories: IncidentCategory[];
  requestCategories: RequestCategory[];
  rooms: string[];
  visitorOnly: boolean;
}

export interface Employee {
  id: string;
  name: string;
  username: string;
  phone: string;
  teamName: string;
  roleName: string;
  roleId: string;
  teamId: string;
  isSupervisor: boolean;
  defaultTimezone: string;
  email: Nullable<string>;
  lastLoggedAt: Nullable<Date>;
  createdAt: Nullable<Date>;
}

export interface OperationCategory {
  id: string;
  category: string;
  autoClose: Nullable<boolean>;
  parent: OperationCategory;
  subcategories: OperationCategory[];
}

export interface TeamOperationCategory {
  id: string;
  team: Team;
  operationCategory: OperationCategory;
  defaultEmployee: Nullable<Employee>;
  autoClose: Nullable<boolean>;
  autoQualify: Nullable<boolean>;
  taskSla: Nullable<string>;
  linkedServices: Service[];
  startsAt: Nullable<string>;
  endsAt: Nullable<string>;
}

export interface NotificationChannel {
  id: string;
  isActive: boolean;
  platform: NotificationPlatformEnum;
  value: string;
  team: Team;
  createdAt: Date;
}

export enum HotelRoomTypeEnum {
  ROOM = "ROOM",
  APARTMENT = "APARTMENT",
}

export enum StatusHkEnum {
  CLEANED = "CLEANED",
  DIRTY = "DIRTY",
  INSPECTED = "INSPECTED",
}

export enum StatusOccupancyEnum {
  OCCUPIED = "OCCUPIED",
  FREE = "FREE",
  OUT_OF_ORDER = "OUT_OF_ORDER",
}

export interface HotelRoom {
  id: string;
  name: string;
  type: HotelRoomTypeEnum;
  statusHk: StatusHkEnum;
  statusOccupancy: StatusOccupancyEnum;
  createdAt: Date;
}

export enum UnremovableEmployeeReasonEnum {
  SUPERVISOR = "supervisor",
  DEFAULT_EMPLOYEE = "default_employee",
}
export enum UnremovableHotelServiceReasonEnum {
  ON_GOING_TASKS = "on_going_tasks",
}

export interface Team {
  id: string;
  name: string;
  supervisor: Nullable<Employee>;
  employeesNbr: number;
  employees: string[];
  createdAt: Nullable<Date>;
}

export enum GuestTypeEnum {
  CURRENT = "CURRENT",
  FUTURE = "FUTURE",
  PAST = "PAST",
  VISITOR = "VISITOR",
}

export interface GuestChannel {
  id: string;
  botId: string;
  name: string;
}

export interface Guest {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  preferredLanguage: string;
  hotel: Hotel;
  guestType: Nullable<GuestTypeEnum>;
  room: Nullable<string>;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  stay: Nullable<Stay>;
  createdAt: Date;
  channels: GuestChannel[];
}

export interface Stay {
  id: string;
  startsAt: Date;
  endsAt: Date;
  room: Nullable<string>;
  guest: Guest;
}

export type ActionType = "BOOKING" | "EXTERNAL" | "INCIDENT" | "ORDER" | "REQUEST";

export interface Action {
  id: string;
  type: ActionType;
  name: string;
  description: string;
  createdBy: Employee | null;
  employeeDeleted: boolean;
  code: string;
}

export interface ServiceCategory {
  id: string;
  name: string;
  code: string;
  type: string;
  actionTypes: ActionType[];
  serviceNames: string[];
  defaultTarget: GuestTypeEnum[];
  operationCategoryId: string;
}

export interface Service {
  id: string;
  name: string;
  description: string;
  isBookable: boolean | null;
  requiresVerification: boolean | null;
  category: ServiceCategory;
  allTranslationsAreSet: boolean;
  actionType: Nullable<ActionType>;
  createdAt: Date;
  archivedAt: Nullable<Date>;
}

export interface ServiceTranslation {
  id: string;
  language: AcceptedLanguages;
  type: string;
  description: string;
}

export interface Media {
  id: string;
  url: string;
}

export interface Menu {
  id: string;
  url: string;
  language: AcceptedLanguages;
}

export interface ServiceDetails {
  id: string;
  name: string;
  openingHours: string;
  isBookable: boolean | null;
  requiresVerification: Nullable<boolean>;
  category: ServiceCategory;
  actionType: Nullable<ActionType>;
  translations: ServiceTranslation[];
  pricing: string;
  target: GuestTypeEnum[];
  images: Media[];
  menus: Menu[];
  createdAt: Date;
  archivedAt: Nullable<Date>;
}

export interface Category {
  id: string;
  parentId: Nullable<string>;
  parentName: Nullable<string>;
  name: string;
  code: string;
}
export type IncidentCategory = Category;
export type RequestCategory = Category;

export interface LocationType {
  id: string;
  name: string;
  code: string;
}
export interface Location {
  id: string;
  name: string;
  code: string;
  type: LocationType;
}
export interface SubLocation {
  id: string;
  name: string;
  location: Location;
  createdAt: Date;
}

export interface Incident extends Action {
  id: string;
  room: Nullable<string>;
  floor: number | null;
  incidentCategory: Nullable<IncidentCategory>;
  location: Location;
  images: string[];
  locationInfo: string;
  finalResultImage: Nullable<string>;
}

export interface CommonGuestNeedAction extends Action {
  hotelService: Nullable<Service>;
  guest: Nullable<Guest>;
}

export interface Booking extends CommonGuestNeedAction {
  numberOfPeople: number;
  startsAt: Date;
  endsAt: Date;
}

export type Order = CommonGuestNeedAction;

export interface Request extends Action {
  requestType: RequestTypeEnum;
  room: Nullable<string>;
  locationInfo: Nullable<string>;
  location: Nullable<Location>;
  guest: Nullable<Guest>;
  requestCategory: Nullable<RequestCategory>;
  images: string[];
}

export interface GuestNeedCommon extends Action {
  id: string;
  hotelService: Nullable<Service>;
  guest: Nullable<Guest>;
}

export type GuestAskingType = Booking | Order | Request;

export interface Verification extends Action {
  id: string;
  guest: Nullable<Guest>;
}

export type OperationStatus = "CANCELED" | "CLOSE" | "DO" | "DONE" | "IN_PROGRESS" | "QUALIFY";

export type TaskStatus = "BLOCKED" | "CANCELLED" | "DONE" | "EXPIRED" | "IN_PROGRESS" | "NEW" | "OVERDUE";

export enum OperationCancellationReasonEnum {
  UNAVAILABLE_SERVICE_AND_GUEST_DECLINED_ALTERNATIVE = "UNAVAILABLE_SERVICE_AND_GUEST_DECLINED_ALTERNATIVE",
  UNAVAILABLE_SERVICE_AND_GUEST_NOT_REACHABLE = "UNAVAILABLE_SERVICE_AND_GUEST_NOT_REACHABLE",
  FAKE_GUEST_NEED = "FAKE_GUEST_NEED",
  FAKE_OR_NOT_CONFIRMED = "FAKE_OR_NOT_CONFIRMED",
  DUPLICATED_INCIDENT = "DUPLICATED_INCIDENT",
  DUPLICATED_GUEST_NEED = "DUPLICATED_GUEST_NEED",
  OTHER = "OTHER",
  NONE = "NONE",
}

export enum TaskActionsEnum {
  QUALIFY = "QUALIFY",
  TRANSFER = "TRANSFER",
  CANCEL = "CANCEL",
  PROCESS = "PROCESS",
  BLOCK = "BLOCK",
  CLOSE = "CLOSE",
  REQUALIFY = "REQUALIFY",
  MODIFY = "MODIFY",
  TAKE_IN_CHARGE = "TAKE_IN_CHARGE",
}

export interface Operation {
  id: string;
  status: OperationStatus;
  action: Action;
  cancellationReason: Nullable<OperationCancellationReasonEnum>;
  updatedAt: Nullable<Date>;
  createdAt: Date;
}

export interface OperationDetails extends Operation {
  tasks: Task[];
}

export interface Task {
  id: string;
  title: string;
  description: string;
  comment: string;
  instructions: string;
  completeAt: Date;
  processedAt: Nullable<Date>;
  currentStatus: TaskStatus;
  type: OperationStatus;
  assignedTo: Nullable<string>;
  processedBy: Nullable<string>;
  createdBy: Nullable<string>;
  operation: Operation;
  hotel: Hotel;
  createdAt: Date;
  isAudioComment: boolean;
  areAudioInstructions: boolean;
  possibleActions: TaskActionsEnum[];
}

export interface APIError {
  errorCode: string;
  message: string;
  httpStatusCode: number;
  errorMetadata: unknown;
}

export interface Pageable<T> {
  data: T[];
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  currentPage: number;
}

export interface TaskFilterModel {
  type: Record<string, boolean>;
  employee: string | null;
  status: Record<string, boolean>;
  dateRange: Array<Date | null>;
}

export interface GuestNeed {
  id: string;
  type: ActionType;
  name: string;
  description: string;
  createdAt: Date;
  code: string;
  status: OperationStatus;
  serviceName: string;
  serviceCategory: string;
}

export interface Permission {
  id: string;
  code: UserPermissions;
  name: string;
  description: string;
}
export interface Role {
  id: string;
  code: UserRoleCodeEnum;
  name: string;
  description: string;
  permissions: Permission[];
}

export interface User {
  id: string;
  name: string;
  username: string;
  employeeId: string;
  hotel: Hotel;
  role: Role;
  teamName: string;
  teamId: string;
  defaultTimezone: Nullable<string>;
  passwordChanged: boolean;
  hasViewedGuide: boolean;
}

export interface TaskStats {
  due: number;
  overdue: number;
  done: number;
}

export enum GuestNeedTypeEnum {
  REQUEST = "REQUEST",
  BOOKING = "BOOKING",
  ORDER = "ORDER",
  EXTERNAL = "EXTERNAL",
}

export enum WeekDaysEnum {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export interface FaqQuestion {
  id: string;
  question: string;
  response: Nullable<string>;
  category: FaqCategory;
}

export interface FaqCategory {
  id: string;
  name: string;
  questions?: FaqQuestion[];
}

export enum NotificationChannelTypeEnum {
  TEAM_CHANNEL = "team_channel",
  HOTEL_CHANNEL = "hotel_channel",
}

export enum NotificationReasonEnum {
  OVERDUE_TASKS = "overdue_tasks",
  BOOKING_REMINDER = "booking_reminder",
  NEW_TASK = "new_task",
  ASSIGNED_TASK = "assigned_task",
  OPERATION_DONE = "operation_done",
}

export enum NotificationReceiverCategoryEnum {
  GUEST = "guest",
  EMPLOYEE = "employee",
  TEAM = "team",
}

export interface NotificationHistory {
  id: string;
  createdAt: Date;
  code: string;
  reason: NotificationReasonEnum;
  platform: NotificationPlatformEnum;
  receiverCategory: Nullable<NotificationReceiverCategoryEnum>;
  receiverName: Nullable<string>;
  receiverValue: string;
}

export type OpeningHour<T> = {
  from: T;
  to: T;
};

export type OpeningHours<T> = Record<keyof typeof WeekDaysEnum, Array<OpeningHour<T>>>;
