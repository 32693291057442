import { hotelsUrl } from "~api/urls";
import axiosInstance from "~utils/axiosInstance";
import { isDefined } from "~utils/boolean-utils";
import { voidFunction } from "~utils/functions";
import type { Consumer } from "~utils/types";
import type { Hotel, Location, Pageable, Service } from "~utils/types/global-types";

import type { ServicesFilters } from "./types";

import type { AxiosProgressEvent, AxiosRequestConfig } from "axios";

export const getHotel = async (id: string): Promise<Pageable<Hotel>> => {
  const { data } = await axiosInstance.get<Pageable<Hotel>>(`${hotelsUrl}/${id}`);

  return data;
};

export const getHotelServices = async (hotelId: string, filters: ServicesFilters): Promise<Pageable<Service>> => {
  const params: ServicesFilters = {};
  for (const key in filters) {
    if (isDefined(filters[key])) {
      params[key] = filters[key];
    }
  }
  const { data } = await axiosInstance.get<Pageable<Service>>(`${hotelsUrl}/${hotelId}/services`, { params });

  return data;
};

export const createHotelService = async (
  hotelId: string,
  dto: FormData,
  onUploadProgressFn: Consumer<AxiosProgressEvent> = voidFunction,
): Promise<Service> => {
  const config: AxiosRequestConfig = {
    onUploadProgress: onUploadProgressFn,
  };
  const { data } = await axiosInstance.post<Service>(`${hotelsUrl}/${hotelId}/services`, dto, config);

  return data;
};
export const getHotelLocations = async (hotelId: string): Promise<Location[]> => {
  const { data } = await axiosInstance.get<Location[]>(`${hotelsUrl}/${hotelId}/locations`);

  return data;
};

export const updateHotel = async (hotelId: string, dto: FormData): Promise<Hotel> => {
  const { data } = await axiosInstance.put<Hotel>(`${hotelsUrl}/${hotelId}`, dto);

  return data;
};
