/* eslint-disable no-param-reassign */

import { ESortDirections, ETaskTableSortOptions } from "~api/tasks/types";

import type { TaskTableSearchParams } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type TaskTableSearchParamsState = {
  params: TaskTableSearchParams;
};
const initialState: TaskTableSearchParamsState = {
  params: {
    page: 1,
    limit: 10,
    keyword: "",
    sort: ETaskTableSortOptions.DEFAULT,
    order: ESortDirections.ASC,
  },
};
const todoTasksSearchParamsSlice = createSlice({
  name: "todo-tasks-search-params",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.page = action.payload;
    },
    setPageLimit: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.limit = action.payload;
    },
    setKeyword: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.params.keyword = action.payload;
    },
    setSortOption: (state, action: PayloadAction<ETaskTableSortOptions>) => {
      const draft = state;
      draft.params.sort = action.payload;
    },
    setSortOrder: (state, action: PayloadAction<ESortDirections>) => {
      const draft = state;
      draft.params.order = action.payload;
    },
    resetPageNumber: (state) => {
      const draft = state;
      draft.params.page = initialState.params.page;
    },
    resetParams: () => {
      return initialState;
    },
  },
});

export const { setPageNumber, setKeyword, setSortOrder, setPageLimit, setSortOption, resetPageNumber, resetParams } =
  todoTasksSearchParamsSlice.actions;

export const { reducer: todoTasksSearchParamsReducer } = todoTasksSearchParamsSlice;
