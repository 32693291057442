import { useInitTracking } from "~lib/mixpanel/hooks";

import type { FC, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  pageTitle: string;
}

export const EventsTrackingProvider: FC<Props> = ({ pageTitle, children }) => {
  useInitTracking(pageTitle);

  return <>{children}</>;
};
