/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import { getNumberOfAppliedFilters } from "./selectors";
import type {
  CreatedByFilter,
  EmployeeFilter,
  StatusFilter,
  TasksFiltersStateStored,
  TasksFiltersStored,
  TypesFilter,
} from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialFilters: TasksFiltersStored = {
  type: { BOOKING: false, INCIDENT: false, ORDER: false, REQUEST: false },
  status: {
    NEW: false,
    IN_PROGRESS: false,
    DONE: false,
    OVERDUE: false,
    CANCELLED: false,
    BLOCKED: false,
    EXPIRED: false,
  },
  employee: null,
  completeAtMax: null,
  completeAtMin: null,
  createdBy: null,
};

const initialState: TasksFiltersStateStored = {
  filters: initialFilters,
  numberOfAppliedFilters: 0,
  appliedFilters: initialFilters,
};

const finishedTasksFiltersSlice = createSlice({
  name: "finsihedTaskFilters",
  initialState,

  reducers: {
    setTypeFilter: (state, action: PayloadAction<TypesFilter>) => {
      const draft = state;
      const isChecked = draft.filters.type[action.payload];
      draft.filters.type[action.payload] = !isChecked;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setStatusFilter: (state, action: PayloadAction<StatusFilter>) => {
      const draft = state;
      const isChecked = draft.filters.status[action.payload];
      draft.filters.status[action.payload] = !isChecked;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setEmployeeFilter: (state, action: PayloadAction<EmployeeFilter>) => {
      const draft = state;
      draft.filters.employee = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setCreatedByFilter: (state, action: PayloadAction<CreatedByFilter>) => {
      const draft = state;
      draft.filters.createdBy = action.payload;
    },
    setCompleteAtMax: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.filters.completeAtMax = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setCompleteAtMin: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.filters.completeAtMin = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    applyFilters: (state) => {
      const draft = state;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
      draft.appliedFilters = draft.filters;
    },
    resetFilters: (state) => {
      const draft = state;
      draft.filters = { ...initialFilters };
      draft.numberOfAppliedFilters = 0;
    },
  },
});

export const {
  setTypeFilter,
  setStatusFilter,
  setEmployeeFilter,
  setCreatedByFilter,
  applyFilters,
  resetFilters,
  setCompleteAtMax,
  setCompleteAtMin,
} = finishedTasksFiltersSlice.actions;

export const { reducer: finishedTasksFiltersReducer } = finishedTasksFiltersSlice;
