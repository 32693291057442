import type { Callback } from "~utils/types";

import type { Dict } from "mixpanel-browser";

export enum UserEventsEnum {
  FILTER_APPLIED = "Filter Applied",
  QUICK_FILTER_CLICKED = "Quick Filter Clicked",
  TAB_CLICKED = "Tab Clicked",
  GUIDE_DOWNLOADED = "Guide Downloaded",
  PAGE_VIEW = "Page View",
}

export type CreateTrackEventHandlerFn = (params: {
  event: UserEventsEnum;
  callback: Callback;
  eventProps?: Dict;
}) => Callback;
export type TrackEventFn = (params: { event: UserEventsEnum; eventProps?: Dict }) => void;

export type UseTrackerType = () => {
  createTrackEventHandler: CreateTrackEventHandlerFn;
  trackEvent: TrackEventFn;
};
