import { useCurrentUser } from "./current-user/hooks";
import { useGuestsFilters } from "./guest-filters/hooks";
import { useGuestsPageSearchParams } from "./guests-search-params/hooks";
import type { AppDispatch, IUseGlobalState, RootState } from "./types";

import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";

// Dispatch Type is a big boy type, for the sake of keeping
// the most complete intelisense we're going to ignore this rule, sorry eslint.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useGlobalState: IUseGlobalState = () => {
  const { removeCurrentUser: resetCurrentUser } = useCurrentUser();
  const { resetFilters: resetGuestsFilters } = useGuestsFilters();
  const { resetParams: resetGuestsParams } = useGuestsPageSearchParams();

  const resetGlobalStateFn = () => {
    resetCurrentUser();
    resetGuestsFilters();
    resetGuestsParams();
  };

  return {
    resetGlobalState: resetGlobalStateFn,
  };
};
