/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import type { GuestNeedsTableSearchParams } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type TaskTableSearchParamsState = {
  params: GuestNeedsTableSearchParams;
};
const initialState: TaskTableSearchParamsState = {
  params: {
    page: 1,
    limit: 5,
    keyword: null,
  },
};

const guestNeedsSearchParamsSlice = createSlice({
  name: "guest-need-search-params",
  initialState,

  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.page = action.payload;
    },
    setPageLimit: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.limit = action.payload;
    },
    setKeyword: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.params.keyword = action.payload;
    },
    resetPageNumber: (state) => {
      const draft = state;
      draft.params.page = initialState.params.page;
    },
    resetSearchParams: () => {
      return initialState;
    },
  },
});

export const { setPageNumber, setPageLimit, setKeyword, resetPageNumber, resetSearchParams } =
  guestNeedsSearchParamsSlice.actions;

export const { reducer: guestNeedsSearchParamsReducer } = guestNeedsSearchParamsSlice;
