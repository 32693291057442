import { useAppSelector } from "~store/hooks";
import type { RootState } from "~store/types";

import type { TasksFiltersStateStored, TasksFiltersStored } from "./types";

export const useSelectTodoTasksFilters = (): TasksFiltersStateStored => {
  return useAppSelector((state: RootState) => state.todoTaskFilters);
};

export const getNumberOfAppliedFilters = (filters: TasksFiltersStored): number => {
  return (
    Number(Object.values(filters.type).includes(true)) +
    Number(filters.employee !== null) +
    Number(Object.values(filters.status).includes(true)) +
    Number(filters.completeAtMax !== null) +
    Number(filters.completeAtMin !== null)
  );
};

export const useGetNumberOfAppliedTodoTasksFilters = (): number => {
  return useAppSelector((state: RootState) => state.todoTaskFilters.numberOfAppliedFilters);
};
