/* eslint-disable no-param-reassign */

import type { CreateServicePageStateStored, OpeningHoursDataStored } from "./types";
import { CreateServicePageSteps } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: CreateServicePageStateStored = {
  currentStep: CreateServicePageSteps.CREATE_SERVICE,
  requiredOperationCategory: null,
  openingHoursData: {
    MONDAY: [],
    TUESDAY: [],
    WEDNESDAY: [],
    THURSDAY: [],
    FRIDAY: [],
    SATURDAY: [],
    SUNDAY: [],
  },
};

const createServicePageStateSlice = createSlice({
  name: "create-service-page",
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<CreateServicePageSteps>) => {
      const draft = state;
      draft.currentStep = action.payload;
    },
    setRequiredOperationCategory: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.requiredOperationCategory = action.payload;
    },
    setOpeningHours: (state, action: PayloadAction<OpeningHoursDataStored>) => {
      const draft = state;
      draft.openingHoursData = action.payload;
    },

    resetState: () => {
      return { ...initialState };
    },
  },
});

export const { setRequiredOperationCategory, setOpeningHours, setCurrentStep, resetState } =
  createServicePageStateSlice.actions;

export const { reducer: createServicePageStateReducer } = createServicePageStateSlice;
