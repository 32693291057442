import type { Consumer, Nullable } from "~utils/types";

export enum TasksPageTabsEnum {
  TODO_TASKS = "todo_tasks",
  FINISHED_TASKS = "finished_tasks",
  CREATED_OPERATIONS = "created_operations",
}

export enum TodoTasksFastFiltersEnum {
  ALL = "all",
  OVERDUE = "overdue",
  ASSIGNED_TO_ME = "assigned_to_me",
}

export enum FinishedTasksFastFiltersEnum {
  ALL = "all",
  DONE = "done",
  CANCELLED = "cancelled",
  EXPIRED = "expired",
  BLOCKED = "blocked",
}
export enum CreatedOperationsFastFiltersEnum {
  ALL = "all",
  BY_ME = "by_me",
  BY_MY_TEAM = "by_my_team",
}

export type TasksPageState = {
  currentTab: TasksPageTabsEnum;
  currentTodoTasksFastFilter: Nullable<TodoTasksFastFiltersEnum>;
  currentFinishedTasksFastFilter: Nullable<FinishedTasksFastFiltersEnum>;
  currentCreatedOperationsFastFilter: Nullable<CreatedOperationsFastFiltersEnum>;
};

export type UseTasksPageStateReturnType = {
  setCurrentTab: Consumer<TasksPageTabsEnum>;
  setCurrentTodoTasksFastFilter: Consumer<Nullable<TodoTasksFastFiltersEnum>>;
  setCurrentFinishedTasksFastFilter: Consumer<Nullable<FinishedTasksFastFiltersEnum>>;
  setCurrentCreatedOperationsFastFilter: Consumer<Nullable<CreatedOperationsFastFiltersEnum>>;
};
