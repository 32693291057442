import { voidFunction } from "~utils/functions";

import { getConnectedUser, updateUser } from "./repository";
import type { UseGetConnectedUserType, UseUpdateUserType } from "./types";

import { useMutation, useQuery } from "react-query";

export const useGetConnectedUser: UseGetConnectedUserType = (onSuccessFn = voidFunction, onErrorFn = voidFunction) => {
  return useQuery({
    queryKey: ["connected-user"],
    queryFn: async () => getConnectedUser(),
    onSuccess: onSuccessFn,
    onError: onErrorFn,
    enabled: false,
  });
};

export const useUpdateUser: UseUpdateUserType = (onSuccessFn = voidFunction, onErrorFn = voidFunction) => {
  return useMutation({
    mutationKey: ["connected-user"],
    mutationFn: async (dto) => updateUser(dto),
    onSuccess: onSuccessFn,
    onError: onErrorFn,
  });
};
