import axiosInstance from "~utils/axiosInstance";
import type { User } from "~utils/types/global-types";

import type { UpdateUserDTO } from "./types";

import type { AxiosResponse } from "axios";

export const getConnectedUser = async (): Promise<User> => {
  const { data }: AxiosResponse<User> = await axiosInstance.get(`/me`);
  return data;
};

export const updateUser = async (dto: UpdateUserDTO): Promise<User> => {
  const { data }: AxiosResponse<User> = await axiosInstance.put("/me", dto);
  return data;
};
