import type { Nullable } from "~utils/types";

import type {
  CreatedOperationsFastFiltersEnum,
  FinishedTasksFastFiltersEnum,
  TasksPageState,
  TodoTasksFastFiltersEnum,
} from "./types";
import { TasksPageTabsEnum } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: TasksPageState = {
  currentTab: TasksPageTabsEnum.TODO_TASKS,
  currentTodoTasksFastFilter: null,
  currentFinishedTasksFastFilter: null,
  currentCreatedOperationsFastFilter: null,
};

const tasksPageSlice = createSlice({
  name: "tasks-page",
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<TasksPageTabsEnum>) => {
      const draft = state;
      draft.currentTab = action.payload;
    },
    setCurrentTodoTasksFastFilter: (state, action: PayloadAction<Nullable<TodoTasksFastFiltersEnum>>) => {
      const draft = state;
      draft.currentTodoTasksFastFilter = action.payload;
    },
    setCurrentFinishedTasksFastFilter: (state, action: PayloadAction<Nullable<FinishedTasksFastFiltersEnum>>) => {
      const draft = state;
      draft.currentFinishedTasksFastFilter = action.payload;
    },
    setCurrentCreatedOperationsFastFilter: (
      state,
      action: PayloadAction<Nullable<CreatedOperationsFastFiltersEnum>>,
    ) => {
      const draft = state;
      draft.currentCreatedOperationsFastFilter = action.payload;
    },
  },
});

export const {
  setCurrentTab,
  setCurrentTodoTasksFastFilter,
  setCurrentFinishedTasksFastFilter,
  setCurrentCreatedOperationsFastFilter,
} = tasksPageSlice.actions;

export const { reducer: tasksPageReducer } = tasksPageSlice;
