import type { Callback, Consumer, Nullable } from "~utils/types";

export enum ZonesPageTabsEnum {
  ZONES = "zones",
  ROOMS = "rooms",
  PUBLIC_AREAS = "public_areas",
  PRIVATE_AREAS = "private_areas",
}

export enum ZonesPageModalsEnum {
  CREATE_ROOM = "create_room",
  UPDATE_ROOM = "update_room",
  DELETE_ROOM = "delete_room",
  CREATE_SUB_LOCATION = "create_sub_location",
  UPDATE_SUB_LOCATION = "update_sub_location",
  DELETE_SUB_LOCATION = "delete_sub_location",
  DETAILS_POPOVER = "details_popover",
}

export type ZonesPageState = {
  currentTab: ZonesPageTabsEnum;
  detailsButtonAnchorId: Nullable<string>;
  currentModal: Nullable<ZonesPageModalsEnum>;
  selectedRowId: Nullable<string>;
};

export type IUseZonesPageState = () => {
  setCurrentTab: Consumer<ZonesPageTabsEnum>;
  openModal: Consumer<ZonesPageModalsEnum>;
  closeModals: Callback;
  setDetailsButtonAnchorId: Consumer<Nullable<string>>;
  setSelectedRowId: Consumer<Nullable<string>>;
  restState: Callback;
};
